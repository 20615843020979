import {Box, Center, Text} from 'native-base';
import DashboardLayout from '@ui/layout/DashboardLayout';
import NavigationButton from '@ui/components/buttons/NavigationButton';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useTranslation} from 'react-i18next';

const NotFound = () => {
    const {t} = useTranslation();

    return (
        <DashboardLayout>
            <Center flex={1}>
                <Text variant='h4' textAlign='center'>
                    {t('general.notFound')}
                </Text>

                <Box h='8'/>

                <NavigationButton path={getScreen('clubhouse')} w='full'>
                    {t('general.goHome')}
                </NavigationButton>
            </Center>
        </DashboardLayout>
    );
};

export default NotFound;
